import type {
  LocaleString,
  Maybe,
  Office,
} from '@/.generated/sanityTypes/graphql'

import React from 'react'

import { MailIcon, PhoneIcon, PinIcon } from '@/components/icons'
import Card from '@/components/card'
import Img from '@/components/Img'
import { VimeoOffice } from '@/components/vimeoOffice'

import { useRouter } from 'next/router'
import { getTypedLocale } from '../lib/typeHelper'

const getCompanyForm = (country?: Maybe<LocaleString>): string => {
  if (country?.en === 'Italy') return 'S.r.l.'
  return 'AG'
}

const Location = ({
  city,
  image,
  addressLine1,
  addressLine2,
  street,
  postalCodeCity,
  country,
  email,
  phone,
  mapLink,
  video,
  vimeoVideo,
}: Partial<Office>): JSX.Element => {
  const locale = getTypedLocale(useRouter().locale)

  return (
    <div className="mb-10">
      <div className="text-2xl leading-tight text-red-500">
        {city?.[locale]}
      </div>
      <div className="relative mt-5 aspect-video h-2/3">
        {/* Video has precedence over image */}

        {vimeoVideo ? (
          <VimeoOffice videoId={+vimeoVideo} />
        ) : video ? (
          <video
            onMouseOver={(e) => (e.target as HTMLVideoElement).play()}
            onMouseOut={(e) => (e.target as HTMLVideoElement).pause()}
            loop
            muted
          >
            <source src={video?.asset?.url ?? ''} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        ) : image ? (
          <Img
            sizes="(min-width: 900px) 352px, (min-width: 640px) 41.67vw, 100vw"
            image={image}
            objectFit="cover"
            className="absolute h-full w-full bg-gray-200"
          />
        ) : null}
      </div>
      <div className="mt-5 text-xl leading-tight">
        TBF + Partner {getCompanyForm(country)}
        {addressLine1?.[locale] ? (
          <>
            <br /> {addressLine1[locale]}
          </>
        ) : null}
        {addressLine2?.[locale] ? (
          <>
            <br /> {addressLine2[locale]}
          </>
        ) : null}
        <br /> {street}
        <br /> {postalCodeCity?.[locale]}
        <br /> {country?.[locale]}
      </div>
      <div className="mt-3 text-sm text-red-500">
        {email && (
          <a href={`malto:${email}`} className="flex items-center">
            <MailIcon className="icon-fill-red mr-1 h-4 w-4" />
            {email}
          </a>
        )}
        {phone && (
          <a href={`tel:${phone}`} className="flex items-center text-red-500">
            <PhoneIcon className="icon-fill-red mr-1 h-4 w-4" />
            {phone}
          </a>
        )}
        {mapLink && (
          <a
            href={mapLink}
            className="flex items-center"
            target="_blank"
            rel="noreferrer"
            aria-label={`Go to google maps for tbf in ${city?.[locale]}`}
          >
            <PinIcon className="icon-fill-red mr-1 h-4 w-4" />
            maps.google.ch
          </a>
        )}
      </div>
    </div>
  )
}

const ContactsList = ({ offices, data }: any) => {
  return (
    <Card headerData={data} contentData={data}>
      <div className="mt-8 grid grid-cols-1 sm:grid-cols-2">
        {offices.map((location: any, index: number) => (
          <div className="m-4" key={index}>
            <Location
              city={location.city}
              image={location.image}
              addressLine1={location.addressLine1}
              addressLine2={location.addressLine2}
              street={location.street}
              postalCodeCity={location.postalCodeCity}
              country={location.country}
              email={location.email}
              phone={location.phone}
              mapLink={location.mapLink}
              video={location.video}
              vimeoVideo={location.vimeoVideo}
            />
          </div>
        ))}
      </div>
    </Card>
  )
}

export default ContactsList
