type FormattedDateProps = {
  dateStart: Date
  dateEnd?: Date
  locale: string
}
export const getFormattedDate = ({
  dateStart,
  dateEnd,
  locale,
}: FormattedDateProps) => {
  const actualStart = new Date(dateStart)
  const actualEnd = dateEnd && new Date(dateEnd)
  const fullDateOptions: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
  }

  if (dateEnd && dateEnd !== dateStart) {
    if (actualStart.getFullYear() !== actualEnd?.getFullYear()) {
      return `${actualStart.toLocaleDateString(
        locale,
        fullDateOptions,
      )} - ${actualEnd?.toLocaleDateString(locale, fullDateOptions)}`
    }
    if (actualStart.getMonth() !== actualEnd?.getMonth()) {
      const dayAndMonthOptions: Intl.DateTimeFormatOptions = {
        month: 'long',
        day: '2-digit',
      }
      return `${actualStart.toLocaleDateString(
        locale,
        dayAndMonthOptions,
      )} - ${actualEnd?.toLocaleDateString(locale, fullDateOptions)}`
    }
    if (actualStart.getDate() !== actualEnd?.getDate()) {
      const dayOptions: Intl.DateTimeFormatOptions = { day: '2-digit' }
      return `${actualStart.toLocaleDateString(
        locale,
        dayOptions,
      )} - ${actualEnd?.toLocaleDateString(locale, fullDateOptions)}`
    }
  }
  return actualStart?.toLocaleDateString(locale, fullDateOptions)
}
