const { fontFamily } = require('tailwindcss/defaultTheme')

/** @type {import('tailwindcss').Config} */
module.exports = {
  content: ['./src/**/*.tsx'],
  theme: {
    fontFamily: {
      sans: ['var(--font-walsheim)', ...fontFamily.sans],
    },
    fontWeight: {
      light: 300,
      normal: 400,
      medium: 500,
    },
    fill: (theme) => ({
      current: 'currentColor',
      red: theme('colors.red.500'),
      white: theme('colors.white'),
    }),
    extend: {
      colors: {
        current: 'currentColor',
        yellow: { 500: '#FFF166' },
        teal: { 400: '#9bd4ce' },
        red: { 100: '#FFF3F3', 500: '#DA291C' },
        gray: { 200: '#F4F4F4', 500: '#968F8B', 700: '#2d3748' },
        'yellow-highlight': '#FFF166',
        'red-highlight': '#DA291C',
        'dark-blue': '#266782',
        'light-gray': '#CAC7C5',
        'black-75': 'rgba(0, 0, 0, 0.75)',
        'slightly-red-gray': '#5f5453',
      },
      spacing: {
        px: '1px',
        '1/4-screen': '25vh',
        '1/3-screen': '33vh',
        '1/2-screen': '50vh',
        '3/4-screen': '75vh',
        '4/5-screen': '80vh',
        '2/3-screen': '66.666667vh',
        '2/3': '66.666667%',
        '16/9': '56.25%',
        '5/4': '125%',
        '1/2-screen-noheader': 'calc(50vh - 65px)',
        'screen-noheader': 'calc(100vh - 65px)',
      },
      inset: {
        '1/2': '50%',
      },
      padding: {
        full: '100%',
      },
      minWidth: {
        48: '12rem',
      },
      minHeight: {
        px: '1px',
        '2/3': '66.66%',
        '2/5-screen': '40vh',
      },
      maxHeight: {
        4: '4rem',
        40: '12rem',
        72: '22rem',
        88: '27rem',
        144: '30rem',
      },
      maxWidth: {
        9: '160px',
        '1/2': '50%',
        '145vh': '145vh',
      },
      height: {
        25: '6.25rem',
        '1/2': '50%',
        '5/6': '83.333333%',
      },
      width: {
        25: '6.25rem',
        28: '7rem',
        72: '18rem',
        '9/10': '90%',
      },
      screens: {
        portrait: { raw: '(orientation: portrait)' },
      },
      scale: {
        80: '.80',
        85: '.85',
      },
      zIndex: {
        '-1': '-1',
        1: '1',
      },
      lineHeight: {
        '1_1': '1.1',
      },
      fontSize: {
        '2.5xl': '1.65rem',
        '7xl': '5rem',
      },
      outline: {
        'yellow-500': '8px solid #FFF166',
      },
    },
    screens: {
      xs: '480px',
      sm: '640px',
      md: '768px',
      lg: '1024px',
      xl: '1280px',
      '2xl': '1536px',
    },
  },
  plugins: [],
}
