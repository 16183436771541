import React, { useRef, useEffect } from 'react'
import { useRouter } from 'next/router'

import Card from '@/components/card'
import Video from '@/components/video'

const AboutUsVideoSection = ({ headerData, videos }: any) => {
  const { query, locale = 'de' } = useRouter()
  const videoIdFromUrl = query?.v
  const videoRef = useRef<HTMLDivElement>(null)

  const longVideoIds = videos?.map((v: any) => v?.videoLong?.[locale]) || []

  // get random video if no url param is passed
  const videosCount = longVideoIds.length || 0
  const isUrlVideoKnown =
    videoIdFromUrl && longVideoIds.includes(videoIdFromUrl)
  const randomId = Math.floor(Math.random() * videosCount)
  const videoId = (isUrlVideoKnown && videoIdFromUrl) || longVideoIds[randomId]

  useEffect(() => {
    if (isUrlVideoKnown && videoRef.current) {
      videoRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      })
    }
  }, [isUrlVideoKnown, videoIdFromUrl, videoRef])

  return (
    <Card headerData={headerData}>
      <div className="mx-auto mt-3 max-w-2xl" ref={videoRef}>
        <Video
          className="absolute left-0 top-0 block h-full w-full outline-yellow-500"
          srcURL={`https://player.vimeo.com/video/${videoId}`}
          title={headerData.title?.[locale]}
        />
      </div>
    </Card>
  )
}

export default AboutUsVideoSection
