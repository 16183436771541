import React from 'react'

import { ChevronDown, ChevronUp } from './icons'
import { cx } from '@monorepo/helper/cx'

type AccordionItemProps = {
  title: string
  isExpanded: boolean
  onExpand: () => void
  onCollapse: () => void
  children: React.ReactNode
  color?: 'red' | 'dark-blue'
}

const AccordionItem: React.FC<AccordionItemProps> = ({
  title,
  isExpanded,
  onExpand,
  onCollapse,
  children,
  color = 'red',
}) => {
  return (
    <section className="mt-2">
      {isExpanded ? (
        <div
          className={cx(
            'border border-b',
            color === 'red' && 'border-red-500',
            color === 'dark-blue' && 'border-dark-blue',
          )}
        >
          <button
            onClick={onCollapse}
            className="ml-auto flex h-10 w-10 items-center justify-center rounded-none bg-red-500 text-white"
          >
            <ChevronUp className="icon-fill-white h-4" />
          </button>
          {children}
        </div>
      ) : (
        <button
          className={cx(
            'flex w-full items-center justify-between rounded-none border hover:bg-red-100 focus:bg-red-100 focus:outline-none',
            color === 'red' && 'border-red-500',
            color === 'dark-blue' && 'border-dark-blue',
          )}
          onClick={onExpand}
        >
          <h2
            className={cx(
              'truncate px-4 leading-5 sm:text-xl',
              color === 'red' && 'text-red-500',
              color === 'dark-blue' && 'text-dark-blue',
            )}
          >
            {title}
          </h2>
          <div className="flex h-10 w-10 flex-none items-center justify-center bg-red-500 text-white">
            <ChevronDown className="icon-fill-white h-4" />
          </div>
        </button>
      )}
    </section>
  )
}

export default AccordionItem
