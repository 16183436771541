import React from 'react'

import BlockContent from '@/components/blockContent'
import Card from '@/components/card'
import { getFormattedDate } from '@/lib/dates'
import Img from '@/components/Img'
import { useRouter } from 'next/router'

// HELPERS

type EventProps = {
  event: any
  index: number
}

const Event = ({ event, index }: EventProps) => {
  const { locale = 'de' } = useRouter()
  const date = getFormattedDate({
    dateStart: event.dateStart,
    dateEnd: event.dateEnd,
    locale,
  })

  return (
    <div
      id={`event-${event._id}`}
      className={`flex flex-col p-5 md:flex-row ${
        index % 2 === 0 ? 'md:flex-row-reverse' : ''
      }`}
    >
      <div className="text-center md:w-1/2 md:px-4">
        <div className="flex h-full items-center justify-center">
          <div>
            <h2 className="text-2xl leading-none text-red-500">
              {event.title[locale]}
            </h2>
            <h3 className="mb-2 text-2xl font-light leading-none">{date}</h3>
            <div className="text-slightly-red-gray leading-5 md:px-4">
              {event.content && (
                <BlockContent blocks={event.content?.[`${locale}Raw`]} />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={`md:w-1/2 ${index % 2 === 0 ? 'pr-2' : 'pl-2'}`}>
        {event.image && (
          <Img
            sizes="(min-width: 1160px) 484px, (min-width: 780px) 43.89vw, (min-width: 640px) calc(100vw - 120px), calc(100vw - 80px)"
            image={event.image}
            objectFit="cover"
            className="min-h-px"
          />
        )}
      </div>
    </div>
  )
}

// MAIN COMPONENT

const EventsList = ({ headerData, events }: any) => {
  const sortedEventsAscending = events?.sort((a: any, b: any) => {
    return new Date(a.dateStart).getTime() - new Date(b.dateStart).getTime()
  })

  return (
    <Card headerData={headerData} className="mx-auto max-w-5xl">
      {sortedEventsAscending?.map((event: any, index: number) => (
        <Event key={event._id} event={event} index={index} />
      ))}
    </Card>
  )
}

export default EventsList
