import React, { useEffect } from 'react'
import type { CompetencesProjectGroup as SanityCompetencesProjectGroup } from '@/.generated/sanityTypes/graphql'
import Img from './Img'
import Link from 'next/link'

import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore from 'swiper'
import { Navigation } from 'swiper/modules'
SwiperCore.use([Navigation])
import 'swiper/css'
import 'swiper/css/navigation'
import { stripNewLines } from '@/lib/withNewLines'
import { theme } from '../../tailwind.config'
import { useRouter } from 'next/router'
import { getTypedLocale } from '../lib/typeHelper'

const backgroundFallbackColors = ['#A9DAD6', '#FFF166', '#266782', '#968F8B']

const isObject = (obj: any): obj is { [key: string]: string } =>
  obj && typeof obj === 'object' && !Array.isArray(obj)
const isString = (str: any): str is string => typeof str === 'string'

const CompetencesProjectGroup = ({
  group,
  rtl,
  index,
}: {
  group: SanityCompetencesProjectGroup
  rtl: boolean
  index: number
}) => {
  const locale = getTypedLocale(useRouter().locale)
  const [isMobile, setIsMobile] = React.useState(false)

  useEffect(() => {
    const mobileSize =
      isObject(theme?.screens) &&
      isString(theme?.screens?.sm) &&
      theme?.screens?.sm
    const matchMedia = window.matchMedia(`(max-width: ${mobileSize})`)
    setIsMobile(matchMedia.matches)
  }, [])

  if (!group.projects) return null
  const projects = rtl ? group.projects.reverse() : group.projects
  const activeSlide = rtl ? projects.length : 0

  const titleSlide = (
    <SwiperSlide
      className="xs:text-4xl text-dark-blue text-3xl"
      style={{
        background:
          backgroundFallbackColors[index % backgroundFallbackColors.length],
      }}
    >
      {group.image && (
        <div className="absolute left-0 top-0 h-full w-full">
          <Img fill objectFit="cover" image={group.image} />
        </div>
      )}
      <h3 className="relative ml-5 mt-5 inline-block bg-red-500 px-2 py-1 leading-none text-white">
        {group.title?.[locale]}
      </h3>
    </SwiperSlide>
  )
  return (
    <div className="relative mb-5 sm:mx-5">
      <Swiper
        spaceBetween={isMobile ? 50 : 25}
        navigation
        initialSlide={activeSlide}
        slidesPerView="auto"
        centeredSlides={isMobile}
        className="competences-projects-swiper"
      >
        {!rtl && titleSlide}
        {projects.map((project, projectIndex) => {
          return (
            <SwiperSlide
              key={`${projectIndex}-${project?._key}`}
              className="w-1/4 bg-white"
            >
              <Link
                href={`/projects/${project?.slug?.current}`}
                className="group"
              >
                <div className="pb-2/3 relative h-0 overflow-hidden object-cover">
                  {project?.image && (
                    <Img
                      fill
                      sizes="(min-width: 640px) 310px, calc(100vw - 60px)"
                      className="transition duration-300 ease-in-out group-hover:scale-105"
                      image={project?.image}
                      objectFit="cover"
                    />
                  )}
                </div>
                <div className="p-2">
                  <h4 className="truncate leading-tight text-red-500">
                    {stripNewLines(project?.title?.[locale])}
                  </h4>
                  <p className="truncate leading-tight text-gray-500">
                    {project?.location?.[locale]}
                  </p>
                </div>
              </Link>
            </SwiperSlide>
          )
        })}
        {rtl && titleSlide}
      </Swiper>
    </div>
  )
}

const CompetencesProjectSection = ({
  projectGroups,
}: {
  projectGroups: SanityCompetencesProjectGroup[]
}) => {
  return (
    <div className="mx-auto w-full max-w-screen-xl">
      {projectGroups.map((group, groupIndex) => {
        const rtl = groupIndex % 2 == 1
        return (
          <CompetencesProjectGroup
            key={`${groupIndex}-${group._key}`}
            rtl={rtl}
            group={group}
            index={groupIndex}
          />
        )
      })}
    </div>
  )
}

export default CompetencesProjectSection
