import React, { useEffect, useRef, useState } from 'react'
import Player from '@vimeo/player'

// MAIN COMPONENT

type VimeoOffice = {
  videoId: number
}

export const VimeoOffice = ({ videoId }: VimeoOffice) => {
  const ref = useRef<HTMLDivElement>(null)
  const [player, setPlayer] = useState<any>(null)

  useEffect(() => {
    if (ref?.current) {
      const player = new Player(ref?.current, {
        id: +videoId,
        background: true,
        portrait: false,
        muted: true,
        playsinline: true,
        responsive: true,
      })

      player.pause()
      setPlayer(player)
    }
  }, [videoId])

  const play = () => {
    player?.play()
  }

  const pause = () => {
    player?.pause()
  }

  return (
    <div className="relative h-full w-full">
      <div
        onMouseEnter={play}
        onMouseLeave={pause}
        onTouchStart={play}
        onTouchEnd={pause}
        ref={ref}
        className="absolute inset-0"
      ></div>
      <div className="w-full" style={{ paddingBottom: '60%' }}></div>
    </div>
  )
}
