import React from 'react'

import { BlackButton } from '@/components/buttons'
import Card from '@/components/card'
import { useTranslations } from 'next-intl'
import { useRouter } from 'next/router'
import Image from 'next/image'
import type { LocaleImage } from '@/.generated/sanityTypes/graphql'
import { getTypedLocale } from '../lib/typeHelper'

// MAIN COMPONENT

type MapProps = {
  data: {
    sectionTitle: any
    title: any
    subtitle: any
    images: LocaleImage
  }
}

export const Map = ({ data }: MapProps) => {
  const locale = getTypedLocale(useRouter().locale)
  const t = useTranslations('about')
  const imageAsset = data.images?.[locale]?.asset
  return (
    <Card headerData={data} noPadding className="w-full max-w-full">
      {imageAsset?.url && (
        <div className="pb-full relative h-0 w-full">
          <Image
            sizes="(min-width: 1360px) 1208px, (min-width: 640px) 91.43vw, 100vw"
            className="absolute h-full w-full text-xl font-medium sm:text-lg sm:font-normal md:text-sm"
            src={imageAsset?.url}
            alt="Locations map"
            width={imageAsset?.metadata?.dimensions?.width || 1000}
            height={imageAsset?.metadata?.dimensions?.height || 1000}
          />
        </div>
      )}

      <div className="flex items-center justify-center">
        <BlackButton
          href={`/${locale}/projects`}
          className="mt-6 sm:absolute sm:-mt-24"
        >
          {t('go_to_projects')}
        </BlackButton>
      </div>
    </Card>
  )
}

export default Map
