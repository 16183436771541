import type { CompetencesProjectGroup } from '@/.generated/sanityTypes/graphql'
import React from 'react'

import ContactsList from '@/components/ContactsList'
import EventsList from '@/components/EventsList'
import Map from '@/components/Map'
import Card from '@/components/card'
import { SectionItem } from '@/components/sectionItemsLayout'
import CompetencesRosettaSection from '@/components/competencesRosettaSection'
import CompetencesProjectSection from '@/components/competencesProjectSection'
import JobOfferSection from '@/components/jobOfferSection'
import AboutUsVideoSection from '@/components/aboutUsVideoSection'

const PageSection = ({
  data,
  rootData,
  context,
  jobOffers,
}: {
  data: any
  rootData?: any
  context?: any
  jobOffers?: any
}) => {
  switch (data._type) {
    case 'aboutUsVideoSection':
      return <AboutUsVideoSection headerData={data} videos={data.videos} />

    case 'jobOfferSection':
      return (
        <JobOfferSection
          headerData={data}
          jobOffers={jobOffers}
          context={context}
        />
      )

    case 'aboutUsMission':
      return <Card headerData={data} />

    case 'aboutUsMap':
      return (
        <Card noStyles>
          <Map data={data} />
        </Card>
      )

    case 'sectionEvents':
      return <EventsList headerData={data} events={rootData?.allEvent} />

    case 'tile':
      return (
        <Card
          noStyles
          className="tbf-hover w-full"
          style={{
            height: 'calc(100vh - 65px)',
          }}
        >
          <SectionItem data={data} textSize="2/3-1-1" />
        </Card>
      )

    case 'mosaicSection':
      return <Card fullWidth contentData={data} />

    case 'contactsListBlock':
      return <ContactsList offices={data.offices} data={data} />

    case 'competencesRosettaSection':
      return <CompetencesRosettaSection section={data} />

    case 'competencesProjectsSection': {
      const projectGroups =
        data.competencesProjectGroups as CompetencesProjectGroup[]
      return <CompetencesProjectSection projectGroups={projectGroups} />
    }

    case 'listBlock':
      return (
        <Card
          contentData={{ ...data, sectionItems: data?.sectionItems }}
          headerData={data.pageHeader}
          colorTheme={!data.isGetToKnowUsBlock ? 'blue_white' : 'red'}
          noStyles={data.isGetToKnowUsBlock && true}
        />
      )

    case 'genericSection':
    default:
      return (
        <Card
          headerData={data.pageHeader ?? data}
          contentData={data}
          colorTheme={data.theme}
        />
      )
  }
}

export default PageSection
