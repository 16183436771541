import Img from '@/components/Img'
import Card from '@/components/card'
import { type CompetencesRosettaSection } from '@/.generated/sanityTypes/graphql'
import { getTypedLocale } from '../lib/typeHelper'
import { useRouter } from 'next/router'

const CompetencesRosettaSection = ({
  section,
}: {
  section: CompetencesRosettaSection
}) => {
  const locale = getTypedLocale(useRouter().locale)
  const image = section.images?.[locale]

  return (
    <Card headerData={section}>
      {image && (
        <Img
          sizes="(min-width: 900px) 768px, (min-width: 640px) calc(83.33vw + 35px), calc(100vw - 32px)"
          image={image}
        />
      )}
    </Card>
  )
}

export default CompetencesRosettaSection
